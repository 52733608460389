
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import LookupNameIds from '@/store/enums/settingsEnums';

  export default defineComponent({
    name: 'service-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const imageFile = ref<File | null>(null);
      const imagePreview = ref<string | null>(null);
      const selectedCategoryId = ref();
      const status = ref(false);
      const serviceDiscount = ref(0);
      const serviceAmount = ref();

      const onImageChange = (event: Event) => {
        const file = (event.target as HTMLInputElement).files?.[0] || null;
        if (file) {
          imageFile.value = file;
          const reader = new FileReader();
          reader.onload = (e) => {
            imagePreview.value = e.target?.result as string;
          };
          reader.readAsDataURL(file);
        }
      };
      const removeImage = () => {
        imageFile.value = null;
        imagePreview.value = null;
      };

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('Service Creating'), [
          translate('settings'),
        ]);
      });

      const validationSchema = Yup.object().shape({
        titleEn: Yup.string()
          .required(() => translate('EN_TITLE_IS_REQUIRED_FIELD'))
          .label('titleEn'),
        titleAr: Yup.string()
          .required(() => translate('AR_TITLE_IS_REQUIRED_FIELD'))
          .label('titleAr'),
        contentEn: Yup.string()
          .required(() => translate('EN_CONTENT_IS_REQUIRED_FIELD'))
          .label('contentEn'),
        contentAr: Yup.string()
          .required(() => translate('AR_CONTENT_IS_REQUIRED_FIELD'))
          .label('contentAr'),
        serviceId: Yup.string()
          .notOneOf(['0'], () => translate('Service Is Required field'))
          .label('Statuses'),
        price: Yup.number()
          .transform((value, originalValue) => {
            if (originalValue === '') {
              return null;
            }
            return value;
          })
          .when('serviceId', {
            is: (serviceId) => serviceId && serviceId !== '0', // when serviceId is not null and not '0'
            then: Yup.number().required(() =>
              translate('Price Is Required field')
            ), // make price required
            otherwise: Yup.number().nullable(), // when serviceId is null or '0', price is not required
          })
          .label('Price'),
        discount: Yup.number()
          .transform((value, originalValue) => {
            if (originalValue === '' || originalValue === null) {
              return null;
            }
            return value;
          })
          .min(0, 'Discount must be greater than or equal to 0')
          .max(100, 'Discount cannot exceed 100')
          .nullable(),
      });

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.SERVICE_TYPE,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);
      const serviceList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.SERVICE_TYPE;
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          submitButton.value.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        values.status = status.value;
        values.price = String(values.price);

        const data = await store.dispatch(Actions.CREATE_SERVICE, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          submitButton.value?.removeAttribute('data-kt-indicator');
          submitButton.value!.disabled = false;
        } else {
          const formData = new FormData();
          if (imageFile.value) {
            formData.append('attachments', imageFile.value);
            await store.dispatch(Actions.UPLOAD_SERVICES_ATTACHMENT, {
              data: formData,
              id: data.data.id,
            });
            const [errorName] = Object.keys(store.getters.getErrors);
            const error = store.getters.getErrors[errorName];
            if (error) {
              Swal.fire({
                text: translate(error[0]),
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: translate('tryAgainExcl'),
                customClass: {
                  confirmButton: 'btn fw-bold btn-light-danger',
                },
              });
            } else {
              Swal.fire({
                text: translate('SUCCESSFULLY_CREATED_SERVICE'),
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: translate('ok'),
                customClass: {
                  confirmButton: 'btn btn-light-primary',
                },
              });
            }
          } else {
            Swal.fire({
              text: translate('SUCCESSFULLY_CREATED_SERVICE'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
          }

          submitButton.value?.removeAttribute('data-kt-indicator');
          submitButton.value!.disabled = false;
        }
      };

      const calculatedPayment = computed(() => {
        const paymentAfterDiscount =
          serviceAmount.value -
          (serviceAmount.value * serviceDiscount.value) / 100;

        return paymentAfterDiscount;
      });

      return {
        onSubmitCreate,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        imagePreview,
        onImageChange,
        removeImage,
        serviceList,
        selectedCategoryId,
        status,
        calculatedPayment,
        serviceDiscount,
        serviceAmount,
      };
    },
  });
